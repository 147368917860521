import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { Formik, Form } from 'formik';
import { object } from 'yup';

import { AuthContext } from '../../../contexts/AuthContext';
import { ErrorContext } from '../../../contexts/ErrorContext';
import { FormGroup } from '../../shared/form/FormGroup';
import { Page } from '../../../components/shared/Page';
import { PasswordChangedModal } from './PasswordChangedModal';
import { Layout } from '../../../components/shared/Layout';
import { Seo } from '../../../components/shared/Seo';
import { Button } from '../../shared/Button';
import { FormInput } from '../../shared/form/FormInput';
import { FormPasswordInput } from '../../shared/form/FormPasswordInput';
import { FormGroupError } from '../../shared/form/FormGroupError';
import { email, loginPassword } from '../../../validations';
import { useGTMEvent } from '../../../hooks/useGTMEvent';

const ValidationSchema = object().shape({ email, password: loginPassword });

export const Login = () => {
  const { login } = useContext(AuthContext);
  const { setGlobalError } = useContext(ErrorContext);
  const pushGTMEvent = useGTMEvent({
    event: 'login:login',
    eventCategory: 'login',
    eventAction: 'click',
    eventLabel: 'login_login',
  });

  const initialFormValues = {
    email: '',
    password: '',
  };

  const onSubmit = async (data, { setFieldError, setSubmitting }) => {
    try {
      pushGTMEvent();
      await login(data);
    } catch (error) {
      if (error.isBadRequest) {
        setFieldError('password', 'Nesprávné uživatelské jméno nebo heslo');
        return setSubmitting(false);
      }
      setGlobalError(error);
    }
  };

  return (
    <Layout showLoginButton={false}>
      <Seo title="Přihlášení" />
      <PasswordChangedModal />
      <Page title="Přihlaste se" description="Zadejte váš mail a heslo.">
        <Formik
          initialValues={initialFormValues}
          validationSchema={ValidationSchema}
          onSubmit={onSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            isSubmitting,
            isValid,
            dirty,
            errors,
            touched,
          }) => (
            <Form className="flex flex-col sm:max-w-sm mx-auto">
              <FormGroup className="mt-0">
                <FormInput
                  type="email"
                  name="email"
                  placeholder="Mail"
                  errors={errors}
                  touched={touched}
                  onChange={handleChange}
                  data-testid="login-email"
                />
                <FormGroupError name="email" />
              </FormGroup>

              <FormGroup>
                <FormPasswordInput
                  name="password"
                  placeholder="Heslo"
                  errors={errors}
                  touched={touched}
                  onChange={handleChange}
                  data-testid="login-password"
                />
                <FormGroupError name="password" />
              </FormGroup>

              <Button
                className="mt-6"
                type="submit"
                disabled={isSubmitting || !dirty || !isValid}
                onClick={handleSubmit}
                data-testid="form-login-action-button"
              >
                Přihlásit se
              </Button>
              <p className="mt-4 text-center">
                <Link to="/ucet/zapomenute-heslo/" className="ml-1 underline">
                  Nepamatuju si heslo
                </Link>
              </p>
            </Form>
          )}
        </Formik>
      </Page>
    </Layout>
  );
};
